export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/account": [~6,[2]],
		"/admin": [~25,[4]],
		"/admin/coupons": [~26,[4]],
		"/admin/monitoring": [~27,[4]],
		"/admin/users": [~28,[4]],
		"/(app)/campaigns": [~7,[2]],
		"/(app)/campaigns/[cid]": [~8,[2]],
		"/(app)/campaigns/[cid]/posts/[pid]": [~9,[2]],
		"/(app)/discovery": [~10,[2]],
		"/(app)/discovery/[username]": [~11,[2]],
		"/(public)/forgot-password": [18,[3]],
		"/(app)/history": [~12,[2]],
		"/(app)/influencers": [~13,[2]],
		"/(app)/influencers/[influencerId]": [~14,[2]],
		"/(app)/influencers/[influencerId]/accounts/[accountId]": [~15,[2]],
		"/(app)/influencers/[influencerId]/accounts/[accountId]/posts/[postId]": [~16,[2]],
		"/(public)/login": [19,[3]],
		"/(public)/login/google": [~20,[3]],
		"/(public)/logout": [21,[3]],
		"/(public)/register": [22,[3]],
		"/(public)/reset-password": [~23,[3]],
		"/(app)/subscriptions": [~17,[2]],
		"/test": [~29],
		"/(public)/verify-email": [~24,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';